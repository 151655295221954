import ApiService from "./api.service";

interface RoleResponse {
  id: string;
  name: string;
  capabilities: any;
  default: number;
}
export interface RoleInfo {
  id: string;
  name: string;
  capabilities?: RoleCapabilities;
  default: number;
}

export class RoleCapabilities {
  doc?: boolean;
  chat?: boolean;
  sticker?: boolean;
  invisible?: boolean;
  whiteBoard?: boolean;
  engineControl?: boolean;
  engder?: boolean;
  publishableTracks?: {
    cam: boolean;
    mic: boolean;
    screenShare: boolean;
  };
}

export interface CreateRoleData {
  name?: string;
  capabilities: RoleCapabilities;
}

export interface GetOrganizationResponse {
  id: string;
  apiKey: string;
  name: string;
  logoPath: string;
  favicon: string;
  color: string;
  engine: "zoom" | "twilio" | "tokbox" | "whereby";
  fallbackEngine: "zoom" | "twilio" | "tokbox" | "whereby";
  exitPath: string;
  domain: string;
  webhookUrl: string;
  supportEmail: string;
  supportTel: string;
  serviceEngine?: string;
  attendBefore: number;
  attendAfter: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface Organization {
  id?: string;
  apiKey?: string;
  name?: string;
  logoPath?: string;
  favicon?: string;
  color?: string;
  engine?: "zoom" | "twilio" | "tokbox" | "whereby";
  fallbackEngine?: "zoom" | "twilio" | "tokbox" | "whereby";
  exitPath?: string;
  domain?: string;
  webhookUrl?: string;
  supportEmail?: string;
  supportTel?: string;
  serviceEngine?: ServiceEngineInfo;
  attendBefore?: number;
  attendAfter?: number;
}

interface ServiceEngineInfo {
  tokbox: {
    apiKey: string;
    apiSecret: string;
    aws: {
      accessKeyId: string;
      secretAccessKey: string;
    };
  };
  twilio: {
    accountSid: string;
    apiKeySecret: string;
    apiKeySid: string;
  };
}

export default class OrganizationService {
  // TODO: post/delete organization
  public static async getOrganization(): Promise<Organization> {
    const res = await this.apiService.get("/Partners/Organizations");
    const data: GetOrganizationResponse = res.data;
    const result: Organization = data as Organization;
    result.serviceEngine = result.serviceEngine
      ? (JSON.parse(data.serviceEngine || "{}") as ServiceEngineInfo)
      : undefined;
    return result;
  }

  public static async editOrganization(
    payload: Organization
  ): Promise<Organization> {
    const res = await this.apiService.put("/Partners/Organizations", payload);
    const data: GetOrganizationResponse = res.data;
    const result: Organization = data as Organization;
    result.serviceEngine = result.serviceEngine
      ? (JSON.parse(data.serviceEngine || "{}") as ServiceEngineInfo)
      : undefined;
    return result;
  }

  public static async getRoles(): Promise<RoleInfo[]> {
    const { data } = await this.apiService.get("/Partners/Roles");
    await data.map((role: RoleResponse) => {
      role.capabilities = JSON.parse(role.capabilities) as RoleCapabilities;
      return role;
    });

    return data;
  }

  public static async createRole(data: CreateRoleData): Promise<boolean> {
    const capabilities = JSON.stringify(data.capabilities);
    const payload = { ...data, capabilities };
    const { status } = await this.apiService.post("/Partners/Roles", payload);
    return status === 201;
  }

  public static async updateRole(
    roleId: string,
    data: RoleInfo
  ): Promise<boolean> {
    const capabilities = JSON.stringify(data.capabilities);
    const payload = { ...data, capabilities };
    const { status } = await this.apiService.put(
      `/Partners/Roles/${roleId}`,
      payload
    );
    return status === 200;
  }

  public static async removeRole(roleId: string): Promise<boolean> {
    const { status } = await this.apiService.delete(
      `/Partners/Roles/${roleId}`
    );
    return status === 200;
  }
  private static apiService = new ApiService("partner");
}
