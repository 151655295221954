import OrganizationService, {
  CreateRoleData,
  RoleInfo
} from "@/services/organization.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";

interface UseRoleOrganizationState {
  loading: boolean;
  roles: RoleInfo[];
  columns: any[];
  selectedRole?: RoleInfo;
  newRole: CreateRoleData;
  showRolePopup: boolean;
  showUpdatePopup: boolean;
}

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    scopedSlots: { customRender: "name" }
  },
  {
    title: "Default",
    dataIndex: "default"
  },
  {
    title: "Capabilities",
    dataIndex: "capabilities",
    scopedSlots: { customRender: "capabilities" }
  },
  {
    title: "Edit/Delete",
    dataIndex: "id",
    scopedSlots: { customRender: "id" }
  }
];
const useRoleOrganization = () => {
  const state = reactive<UseRoleOrganizationState>({
    loading: true,
    roles: [],
    columns,
    selectedRole: undefined,
    newRole: {
      name: "",
      capabilities: {
        doc: true,
        chat: true,
        sticker: true,
        invisible: false,
        whiteBoard: true,
        engineControl: true,
        engder: false,
        publishableTracks: {
          cam: true,
          mic: true,
          screenShare: true
        }
      }
    },
    showRolePopup: false,
    showUpdatePopup: false
  });
  const fetchRoles = async () => {
    state.loading = true;
    const data = await OrganizationService.getRoles();
    state.roles = data;
    state.loading = false;
  };

  const createNewRole = async () => {
    const res = await OrganizationService.createRole(state.newRole);
    if (res) {
      toggleCreateRolePopup();
      fetchRoles();
    }
  };

  const updateRole = async () => {
    if (state.selectedRole) {
      const res = await OrganizationService.updateRole(
        state.selectedRole.id,
        state.selectedRole
      );
      if (res) {
        changeSelectedRole(undefined);
        fetchRoles();
      }
    }
  };

  const toggleCreateRolePopup = () => {
    state.showRolePopup = !state.showRolePopup;
  };

  const changeSelectedRole = (role?: RoleInfo) => {
    state.selectedRole = role;
    state.showUpdatePopup = role === undefined ? false : true;
  };

  const removeRole = async (roleId: string) => {
    const res = await OrganizationService.removeRole(roleId);
    if (res) {
      fetchRoles();
    }
  };
  onMounted(() => {
    fetchRoles();
    state.newRole.capabilities.chat = true;
  });
  return {
    ...toRefs(state),
    createNewRole,
    updateRole,
    removeRole,
    toggleCreateRolePopup,
    changeSelectedRole
  };
};

export default useRoleOrganization;
