
import { Vue, Component } from "vue-property-decorator";
import RoleEditor from "@/components/RoleEditor.vue";
import useRoleOrganization from "@/composables/use-role-organization";
import { get } from "@/store/pathify-helper";

@Component({ components: { RoleEditor } })
export default class Role extends Vue {
  setup(props: any, context: any) {
    const user = get(context, "auth/user");
    const {
      loading,
      columns,
      roles,
      newRole,
      selectedRole,
      showRolePopup,
      showUpdatePopup,
      createNewRole,
      updateRole,
      removeRole,
      changeSelectedRole,
      toggleCreateRolePopup
    } = useRoleOrganization();
    return {
      user,

      // useRoleOrganization
      loading,
      columns,
      roles,
      newRole,
      selectedRole,
      showRolePopup,
      showUpdatePopup,
      createNewRole,
      updateRole,
      removeRole,
      changeSelectedRole,
      toggleCreateRolePopup
    };
  }
}
