
import { Vue, Component, Prop } from "vue-property-decorator";
// eslint-disable-next-line no-unused-vars
import { RoleInfo } from "@/services/organization.service";

@Component
export default class RoleEditor extends Vue {
  @Prop({ type: Object as () => RoleInfo })
  private role!: RoleInfo;
}
